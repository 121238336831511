<template>
    <AppLink href="/info/customer-reviews">
        <div class="tw-h-auto tw-p-1 tw-w-fit tw-m-auto tw-text-center">
            <div class="tw-px-[27px] tw-pb-[27px]">
                <div class="tw-flex tw-items-center tw-pb-1.5">
                    <AppText
                        class="tw-text-chards-purple tw-pr-2"
                        size="small"
                    >
                        {{ ratingLabel }}
                    </AppText>
                    <div class="tw-flex tw-items-center">
                        <template
                            v-for="index in Math.round(review.average_rating)"
                            :key="index"
                        >
                            <div
                                :class="{
                                    'tw-mx-0.5 tw-bg-contain tw-w-4 tw-h-4 tw-inline-block': true,
                                    'tw-bg-[url(\'/images/trustpilot-star.png\')]': true
                                }"
                            />
                        </template>
                    </div>
                    <AppImage
                        alt="Trustpilot"
                        src="/images/trustpilot-logo.svg"
                        classes="tw-w-20 tw-h-5 tw-pl-2"
                        loading="lazy"
                        :width="80"
                        :height="20"
                    />
                </div>
                <AppText
                    size="xsmall"
                    tone="gray"
                >
                    {{ Math.round(review.average_rating * 10) / 10 }}/5 based on {{ review.total_reviews }} Reviews
                </AppText>
            </div>
        </div>
    </AppLink>
</template>

<script>
import AppText from "@app/atoms/AppText.vue";
import AppImage from "@app/atoms/AppImage.vue";
import AppLink from "@app/atoms/AppLink.vue";

export default {
    components: {AppLink, AppImage, AppText},
    props: {
        review: {
            type: Object,
            required: true
        }
    },
    computed: {
        ratingLabel() {
            if (this.review.average_rating >= 4.5) {
                return 'EXCELLENT';
            } else if (this.review.average_rating >= 3.5) {
                return 'GOOD';
            } else if (this.review.average_rating >= 2.5) {
                return 'AVERAGE';
            } else if (this.review.average_rating >= 1.5) {
                return 'BELOW AVERAGE';
            } else {
                return 'POOR';
            }
        }
    }
};
</script>
